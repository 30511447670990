import { useParams } from "react-router-dom";
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import { useCallback, useEffect, useState } from "react";
import { fetchNavbarDataById, fetchProductById } from "../helpers/fetcher";
import { Helmet } from "react-helmet";

interface ParamsType {
  [key: string]: string | undefined;
  id: string;
  code: string;
}
interface SelectedFilters {
  [key: string]: string;
}
const initialSelectedFilters: SelectedFilters = {
  prm_1: "",
  prm_2: "",
  prm_3: "",
  prm_4: "",
  prm_5: "",
  prm_6: "",
  prm_7: "",
};

function ProductByID() {
  const { lang } = useLanguages();
  const { code, id } = useParams<ParamsType>();

  const [loading, setLoading] = useState(true);
  const [product, setProductData] = useState<any>([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(
    initialSelectedFilters
  );
  const fetchData = useCallback(async (id: string) => {
    try {
      setLoading(true);
      const products = await fetchProductById(parseInt(id));
      setProductData(products);
      const navbar = await fetchNavbarDataById(products?.menu_id!);
      setSelectedFilters(navbar.details);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
    // eslint-disable-next-line
  }, [id, code]);
  const { contentManagementData } = useContent();
  const [currentDropdown, setCurrentDropdown] = useState(null);

  const toggleDropdown = (id: any) => {
    setCurrentDropdown(currentDropdown === id ? null : id);
  };
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{product?.code}</title>
        <link rel="icon" type="image/x-icon" href="/favicon/favicon.ico" />
      </Helmet>

      <div className="min-h-screen  bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] mb-20">
        <div className="pt-[130px] w-[90vw] max-w-5xl m-auto">
          {loading ? (
            <div className="flex justify-center items-center h-[50vh]">
              <span className="loading loading-ring loading-xl bg-[#39B085]"></span>
            </div>
          ) : (
            <>
              <TextGenerateEffect
                words={
                  lang === "ka"
                    ? `პროდუქტი ${product?.code}`
                    : `Product ${product?.code}`
                }
                className="mb-4 text-3xl font-extrabold  text-center"
              />

              <div className="shadow-xl rounded-2xl p-4 transition hover:shadow-2xl">
                <div className="flex flex-col md:flex-row gap-8">
                  <img
                    src={`https://montage.ge/img/${product.photo}`}
                    alt={product?.name_en}
                    className="w-full md:w-1/2 rounded-xl shadow-md object-cover"
                    onError={(e) => {
                      e.currentTarget.src = "/assets/images/default-image.png";
                    }}
                  />

                  <div className="flex-1">
                    <h1 className="text-2xl font-bold text-gray-900 mb-6">
                      {lang === "ka" ? product?.name_ka : product?.name_en}
                    </h1>

                    <p className="text-gray-700 text-lg mb-6 leading-relaxed">
                      {product?.description_ka ||
                        product?.description_en ||
                        (lang === "ka" ? "" : "")}
                    </p>

                    <div className="grid grid-cols-2 gap-6 mb-6">
                      {product?.price !== 0 && (
                        <div>
                          <p className="text-md text-gray-500">
                            {lang === "ka" ? "ფასი:" : "price:"}
                          </p>
                          <p className="text-lg font-medium text-gray-800">
                            ₾ {product?.price}
                          </p>
                        </div>
                      )}

                      <div className="py-2">
                        <p className="text-md text-gray-500">
                          {lang === "ka" ? "რაოდენობა:" : "Quantity:"}
                        </p>
                        <p className="text-md font-medium text-gray-800">
                          {product?.stock ? (
                            <div className="flex items-center gap-2 text-green-600 font-semibold">
                              <span>
                                {lang === "ka" ? "მარაგშია" : "In stock"}
                              </span>
                            </div>
                          ) : (
                            <div className="text-red-600 font-semibold">
                              {lang === "ka" ? "შეკვეთით" : "BY ORDER"}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-6 mb-6">
                      {selectedFilters &&
                        [...Array(8)].map((_, index) => {
                          const key = `prm_${index + 1}`;
                          const labelKey = `prm_${index + 1}_${
                            lang === "ka" ? "ge" : "en"
                          }`;
                          const label = selectedFilters[labelKey];
                          const value = product[key];

                          return (
                            value && (
                              <div key={key}>
                                <p className="text-md text-gray-500">
                                  {label}:
                                </p>
                                <p className="text-lg font-medium text-gray-800">
                                  {value}
                                </p>
                              </div>
                            )
                          );
                        })}
                    </div>

                    <div className="rounded-xl text-md bg-black font-bold text-[#2B2D37] cursor-pointer border-2 border-[#e5e7eb] text-center w-full">
                      {product?.pdf ||
                      product?.pdf1 ||
                      product?.pdf2 ||
                      product?.pdf3 ? (
                        <div className="relative inline-block text-left w-full">
                          <button
                            id="dropdownDefaultButton"
                            onClick={() => toggleDropdown(product?.id)}
                            className="bg-white text-gray-700 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center w-full justify-between border border-gray-300"
                          >
                            {Returntext(
                              contentManagementData,
                              "data_sheet",
                              lang
                            )}
                            <svg
                              className={`w-2.5 h-2.5 ml-3 transition-transform duration-200 ${
                                currentDropdown === product?.id
                                  ? "rotate-180"
                                  : ""
                              }`}
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 10 6"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 1l4 4 4-4"
                              />
                            </svg>
                          </button>

                          {currentDropdown === product?.id && (
                            <div className="absolute bottom-full mb-2 bg-gray-100 divide-y divide-gray-100 rounded-lg shadow w-full z-50">
                              <ul className="py-2 text-md text-gray-700">
                                {[
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf",
                                      lang
                                    ),
                                    url: product?.pdf,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf1",
                                      lang
                                    ),
                                    url: product?.pdf1,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf2",
                                      lang
                                    ),
                                    url: product?.pdf2,
                                  },
                                  {
                                    label: Returntext(
                                      contentManagementData,
                                      "pdf3",
                                      lang
                                    ),
                                    url: product?.pdf3,
                                  },
                                ]
                                  .filter((file) => file.url)
                                  .map((file, index) => (
                                    <a
                                      href={`https://montage.ge/img/${file.url}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={() => setCurrentDropdown(null)}
                                      className="block px-4 py-2 text-gray-700 hover:bg-gray-200 w-[100%]"
                                      key={index}
                                    >
                                      <li>{file.label}</li>
                                    </a>
                                  ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      ) : (
                        <button
                          disabled
                          className="bg-white text-gray-400 font-medium rounded-lg text-md px-5 py-2.5 text-center inline-flex items-center w-full justify-between bordercursor-not-allowed"
                        >
                          {Returntext(
                            contentManagementData,
                            "no_pdf_available",
                            lang
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductByID;
