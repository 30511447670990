import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useLanguages } from "../helpers/contLang";

export default function NotFound() {
  const { lang } = useLanguages();

  return (
    <div className="flex flex-col items-center justify-center h-[700px]  bg-gray-100 text-[#2B2D37] main_font_ka">
      <motion.h1
        className="text-9xl font-bold nav_font_ka"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        404
      </motion.h1>
      <motion.p
        className="text-xl text-[#3bb085] mt-4 nav_font_ka"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {lang === "ka" ? "გვერდი ვერ მოიძებნა" : "404 Page Not Found"}
      </motion.p>
      <motion.div
        className="mt-6"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <Link
          to="/"
          className="px-6 py-3  text-white rounded-lg shadow-md bg-[#3bb085] transition duration-300 nav_font_ka"
        >
          {lang === "ka" ? "უკან დაბრუნება" : "Go Home"}
        </Link>
      </motion.div>
    </div>
  );
}
