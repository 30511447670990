import { cn } from "../../.lib/utils";

export const TextGenerateEffect = ({
  words,
  className,
}: {
  words: string;
  className?: string;
}) => {
  return (
    <div className={cn("font-bold ", className)}>
      <div className="mt-4 ">
        <h1 className="text-[#3BB085] leading-snug tracking-wide smallerTxt">
          {words}
        </h1>
      </div>
    </div>
  );
};
