import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import Layout from "./Layout";
import Home from "./views/Home";
import Contact from "./views/Contact";
import Products from "./views/Products";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import Services from "./views/Services";
import Projects from "./views/Projects";
import ProductsSearch from "./views/ProductsSearch";
import { useEffect } from "react";
import { initializeGA, trackPageView } from "./hooks/analytics";
import ProductByID from "./views/Product";
import NotFound from "./views/NotFound";

function AppContent() {
  const location = useLocation();
  const TRACKING_ID = "G-9PLCLLBDGF";

  useEffect(() => {
    initializeGA(TRACKING_ID);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname + location.search);
  }, [location]);

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/products/:categoryName/:categoryId"
          element={<Products />}
        />
        <Route path="/product/:code/:id" element={<ProductByID />} />
        <Route path="/productsSearch/:name" element={<ProductsSearch />} />
        <Route path="/search/:name" element={<ProductsSearch />} />
        <Route path="/services" element={<Services />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AppContent />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
